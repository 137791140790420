import { useStaticQuery, graphql } from 'gatsby'
import { SavequoteCustomProductTypeQuery } from '../_generated/codegen/graphqlTypes'
import { Unpacked } from '../utils/Unpacked'

export type IRepairModelProductInfo = Unpacked<
  SavequoteCustomProductTypeQuery['allSavequoteCustomProductType']['nodes']
>

const useSaveBrandsQuery = (): IRepairModelProductInfo[] => {
  const { allSavequoteCustomProductType }: SavequoteCustomProductTypeQuery =
    useStaticQuery(
      graphql`
        query SavequoteCustomProductType {
          allSavequoteCustomProductType(filter: { name: { ne: "SMA" } }) {
            nodes {
              id
              name
              label
              imageFile {
                publicURL
              }
              mobileBrandModelList {
                imgUrl
                name

                mobileModelSet {
                  id
                  mobileModelURL
                  name
                }
              }
            }
          }
        }
      `
    )
  return allSavequoteCustomProductType.nodes
}

export default useSaveBrandsQuery
